import React from "react";

import Logo from "../logo/Logo";

const Footer = () => {
    return (
        <footer id="footer" className="footer">

            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-12 footer-info">
                            <a href="#" className="logo d-flex align-items-center">
                                <Logo />
                            </a>
                            <p>Your trusted partner in maintaining clean and accurate email lists and ensuring your messages reach the right inboxes and improving your overall email marketing performance."</p>
                            {/* <div className="social-links mt-3">
                                <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                                <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                                <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                                <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
                            </div> */}
                        </div>

                        <div className="col-lg-3 offset-lg-1 col-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <a href="/">Home</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="/services">Services</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="/pricing">Pricing</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="/about">About</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="/contact">Contact</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                            <h4>Contact Us</h4>
                            <p>Have questions or need assistance?<br />Our customer support team is here to help. Reach out to us via email at <a href="mailto:support@emailvalidify.com">support@emailvalidify.com</a><br />or <a href="/contact">send us a query</a></p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="container">
                    <div className="copyright">
                        &copy; 2023-2024 Copyright <strong><span>Email Validify</span></strong>. All Rights Reserved
                    </div>
                    <div className="links">
                        <ul>
                            <li><i className="bi bi-chevron-right"></i> <a href="https://merchant.razorpay.com/policy/OFTqXUz6KGAsgz/shipping" target="_blank">Shipping and Delivery</a></li>
                            <li><i className="bi bi-chevron-right"></i> <a href="/refund-policy">Refund policy</a></li>
                            <li><i className="bi bi-chevron-right"></i> <a href="/privacy-policy">Privacy policy</a></li>
                            <li><i className="bi bi-chevron-right"></i> <a href="/terms-and-conditions">Terms of service</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}


export default Footer;