import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

const MainLayout = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    const body = document.querySelector('body');
    body.classList = [];

    // Add or remove classes based on the route
    if (currentPath === '/') {
      body.classList.add('home-page');
    } else if (currentPath === '/services') {
      body.classList.add('services-page');
    } else if (currentPath === '/contact') {
      body.classList.add('contact-page');
    } else if (currentPath === '/pricing') {
      body.classList.add('pricing-page');
    } else if (currentPath === '/terms-and-conditions') {
      body.classList.add('tos-page');
    } else if (currentPath === '/privacy-policy') {
      body.classList.add('privacy-policy-page');
    }

    // Clean up when component unmounts
    return () => {
      body.classList = [];
    };
  }, [location.pathname]);

  return (
    <div className="layout">
      <Navbar />
      <main className="main-content">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
