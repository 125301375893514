import React from "react";
import PageHeader from '../components/header/PageHeader';
import ServiceScope from "../components/docs/ServiceScope";

function ServiceScopePage() {
    return (
        <div className="page-content document">
            <PageHeader title={`Shipping and Delivery`} />

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ServiceScope />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceScopePage;