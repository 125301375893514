import React, { Component } from "react";
import Modal from "./modal";

export default class EmailValidationStatusModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { data, onClose, show } = this.props
        const statusColorClass = this.getStatusColorClass(data?.status)
        return show ? (
            <Modal onClose={onClose} className="modal-email-status" title="Email Validation Status">
                {!!data ? (
                    <>
                        <div className='modal-email-status-content'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className={`title-email-address ${statusColorClass}`}>{data.email_address}</div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='email-status-info'>
                                        <div className='email-status-info-label'>Status</div>
                                        <div className='email-status-info-value'>{data.status}</div>
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <div className='email-status-info'>
                                        <div className='email-status-info-label'>Message</div>
                                        <div className='email-status-info-value'>{data.message}</div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='email-status-info'>
                                        <div className='email-status-info-label'>Account</div>
                                        <div className='email-status-info-value'>{data.account}</div>
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <div className='email-status-info'>
                                        <div className='email-status-info-label'>Domain</div>
                                        <div className='email-status-info-value'>{data.domain}</div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='email-status-info'>
                                        <div className='email-status-info-label'>MX Found</div>
                                        <div className='email-status-info-value'>{data.mx_found ? 'yes' : 'no'}</div>
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <div className='email-status-info'>
                                        <div className='email-status-info-label'>MX Record</div>
                                        <div className='email-status-info-value'>{data.mx_record}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="modal-loader-wrapper">
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                )}
            </Modal>
        ) : null
    }

    getStatusColorClass = (status) => {
        let statusColorClass = 'email-status-unknow';
        switch (status) {
            case 'valid':
                statusColorClass = 'email-status-valid';
                break;
            case 'unknown':
                statusColorClass = 'email-status-unknown';
                break;
            case 'not_accept_emails':
                statusColorClass = 'email-status-invalid-l1';
                break;
            case 'mailbox_unavailable':
                statusColorClass = 'email-status-invalid-l0';
                break;
        }
        return statusColorClass;
    }
}