import React from "react";

const RefundPolicy = () => {
    return (
        <article className="document">
            <p className="meta-info"><strong>Last updated:</strong> August 17, 2024</p>

            <h2>Introduction</h2>
            <p>At Email Validify ("we," "our," "us"), we strive to ensure your satisfaction with our email validation services (the "Service"). This Refund Policy outlines the conditions under which refunds may be issued for credits purchased through our Service. By purchasing credits, you agree to the terms of this Refund Policy.</p>

            <h2>1. Purchase of Credits</h2>
            <p>Credits are purchased through our website and are used to access our email validation services. The details of available credit packages, including pricing and the number of credits included, are provided on our website.</p>

            <h2>2. Refund Eligibility</h2>
            <p>Refunds are subject to the following conditions:</p>
            <ul>
                <li><strong>Unused Credits:</strong> Refunds are only available for all the credits purchased in a single transaction. Once credits purchased in any transaction have been partially utilized for email validation or any other Service, they are non-refundable. In simple terms, only a full transaction can be refunded/refunded, hence at the time of refund request, user must have the same number of credits (purchased from the same or another transaction) in the account.</li>
                <li><strong>Technical Issues:</strong> If you experience technical issues with our Service that prevent you from using your credits, please contact our support team. We will investigate and may issue a refund if the issue cannot be resolved promptly.</li>
                <li><strong>Incorrect Billing:</strong> If you believe there has been an error in billing or charges related to your credit purchase, please contact us within 30 days of the transaction. We will review the issue and, if confirmed, issue a refund for the incorrect amount.</li>
            </ul>

            <h2>3. Refund Process</h2>
            <h3>3.1. Requesting a Refund</h3>
            <p>To request a refund, please contact our support team at <a href="mailto:support@emailvalidify.com">support@emailvalidify.com</a> with the following information:</p>
            <ul>
                <li><strong>Transaction ID:</strong> The PayPal transaction ID of the purchase.</li>
                <li><strong>Reason for Refund:</strong> A detailed description of why you are requesting a refund.</li>
                <li><strong>Account Information:</strong> The email address associated with your account.</li>
            </ul>

            <h3>3.2. Processing Time</h3>
            <p>Refund requests are reviewed within 7 business days. Once approved, refunds will be processed back to your original payment method through PayPal. Please allow additional time for the refund to be reflected in your PayPal account.</p>

            <h2>4. Non-Refundable Situations</h2>
            <p>The following situations are not eligible for refunds:</p>
            <ul>
                <li><strong>Partial Use of Credits:</strong> Refunds will not be issued for credits that have been partially used.</li>
                <li><strong>Change of Mind:</strong> Refunds are not granted for reasons such as changing your mind or deciding you no longer need the Service.</li>
                <li><strong>Service Interruptions:</strong> While we strive to maintain uninterrupted service, refunds are not issued for minor service disruptions or scheduled maintenance.</li>
            </ul>

            <h2>5. Contact Us</h2>
            <p>If you have any questions about our refund policy or need assistance, please do not hesitate to contact us at <a href="mailto:support@emailvalidify.com">support@emailvalidify.com</a>.</p>

            <h2>6. Changes to This Policy</h2>
            <p>We may update this Refund Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted on our website, and your continued use of the Service constitutes your acceptance of the updated policy.</p>

            <p>By purchasing credits and using our Service, you acknowledge that you have read, understood, and agree to this Refund Policy.</p>
        </article>
    )
}


export default RefundPolicy;