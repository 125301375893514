import React from "react";

import KeyFeaturesImage from '../../../assets/img/key-features.jpg'
import TimeLineIcon from 'remixicon-react/TimeLineIcon'
import StackLineIcon from 'remixicon-react/StackLineIcon'
import CheckMultipleLineIcon from 'remixicon-react/CheckboxMultipleLineIcon'
import ShieldCheckLineIcon from 'remixicon-react/ShieldCheckLineIcon'
import SpamLineIcon from 'remixicon-react/Spam3LineIcon'
import FileCopyLineIcon from 'remixicon-react/FileCopyLineIcon'


const KeyFeaturesSection = () => {
    return (
        <section id="key-features">
            <div className="container">
                <div className="row feature-icons" data-aos="fade-up">
                    <h2 className="section-header">Key Features</h2>

                    <div className="row">

                        <div className="col-xl-12 d-flex content">
                            <div className="row align-self-center gy-4">

                                <div className="col-md-6 icon-box" data-aos="fade-up">
                                    <TimeLineIcon size={36} className="icon"/>
                                    <div>
                                        <h3>Real-Time Verification</h3>
                                        <p>Verify email addresses in real-time for instant results.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                                    <StackLineIcon size={36} className="icon"/>
                                    <div>
                                        <h3>Bulk Verification</h3>
                                        <p>Effortlessly validate large email lists with our bulk verification feature.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                    <CheckMultipleLineIcon size={36} className="icon"/>
                                    <div>
                                        <h3>Syntax and Format Checking</h3>
                                        <p>Ensure correct email syntax and formatting to reduce bounce rates.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                                    <ShieldCheckLineIcon size={36} className="icon"/>
                                    <div>
                                        <h3>Domain Validation</h3>
                                        <p>Validate email domains to enhance email deliverability</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                                    <SpamLineIcon size={36} className="icon"/>
                                    <div>
                                        <h3>Spam Trap Detection</h3>
                                        <p>Identify and avoid potential spam traps that could harm your sender reputation.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                                    <FileCopyLineIcon size={36} className="icon"/>
                                    <div>
                                        <h3>Duplicate Emails Checker</h3>
                                        <p>Seamlessly filter the list for duplicates</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}


export default KeyFeaturesSection;