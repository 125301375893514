import React, { Component } from 'react';

class Modal extends Component {
    /**
     * Render a modal wrapper, along with a tinted background.
     * This is used as a framework for creating consistent-looking modals.
     * @return {React$Node}
     */

    handleOnClose(e, { modalComponent }) {
        const { onClose = () => { } } = this.props;
        if (modalComponent && modalComponent.contains(e.target)) {
            return;
        }

        onClose();
    }

    render() {
        const { children, title, maxWidth, onClose } = this.props;
        return (
            <div className="modal-overlay">
                <div className={`modal-wrapper ${this.props.className}`}
                    style={{maxWidth: maxWidth ? maxWidth : 600}}
                >
                    <div className='modal-header'>
                        {title ? (<div className='modal-title'>{title}</div>) : null}
                        <i className='mdi mdi-close btn-close' onClick={(e) => onClose ? onClose() : {}}></i>
                    </div>
                    <div className='modal-body'>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;