import React, { Component, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ArrowRightIcon from 'remixicon-react/ArrowRightLineIcon'
import Config from "../../../config";

const PreLaunchOfferModal = (props) => {
    const countDownDate = '2024-11-04 00:00:00';
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const targetDate = new Date(countDownDate);
            const now = new Date();
            const diffInMs = targetDate - now;

            if (diffInMs > 0) {
                const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
                const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

                setTimeLeft({ days, hours, minutes, seconds });
            } else {
                // Set to 0 if the time has passed
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        };

        // Initial calculation
        calculateTimeLeft();

        // Update the timer every second
        const intervalId = setInterval(calculateTimeLeft, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [countDownDate]);

    const onClaimOfferNow = () => {
        localStorage.setItem("isClaimOffer", "yes")
        window.location.href = `${Config.APP_BASE}/signup/rlqelM4viXS3gdcyfUuQhh24nNazE2dp`
    }

    return (
        <Modal
            id="pre-launch-offer-modal"
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName="no-backdrop"
        >
            <button type="button" class="btn-close" aria-label="Close" onClick={() => props.onHide()}></button>
            <Modal.Body>
                {/* <div className="offer-tag">PRE LAUNCH OFFER</div> */}
                <div className="offer-title">50% OFF</div>
                <div className="offer-description">Be the first to experience Email Validify! We're launching on November 4th, and as part of our prelaunch celebration, we're offering 50% OFF to everyone who signs up before the launch.
                </div>
                <div className="timer">
                    <div className="values-row">
                        <div className="value-card">
                            <div className="value">{String(timeLeft.days).padStart(2, '0')}</div>
                        </div>
                        <div className="separator">:</div>
                        <div className="value-card">
                            <div className="value">{String(timeLeft.hours).padStart(2, '0')}</div>
                        </div>
                        <div className="separator">:</div>
                        <div className="value-card">
                            <div className="value">{String(timeLeft.minutes).padStart(2, '0')}</div>
                        </div>
                        <div className="separator">:</div>
                        <div className="value-card">
                            <div className="value">{String(timeLeft.seconds).padStart(2, '0')}</div>
                        </div>
                    </div>
                    <div className="labels-row">
                        <div className="label">Days</div>
                        <div className="separator"></div>
                        <div className="label">Hours</div>
                        <div className="separator"></div>
                        <div className="label">Minutes</div>
                        <div className="separator"></div>
                        <div className="label">Seconds</div>
                    </div>
                </div>
                <div className="text-center">
                    <a className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        onClick={onClaimOfferNow}>
                        <span>Claim Offer Now</span>
                        <ArrowRightIcon size={20} />
                    </a>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PreLaunchOfferModal