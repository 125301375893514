
import React from "react";

const ServiceScope = () => {
    return (
        <article className="document">
            <p className="meta-info"><strong>Last updated:</strong> August 17, 2024</p>

            <p>Welcome to Email Validify! We are dedicated to offering exceptional email validation services to enhance the effectiveness of your email campaigns. To ensure transparency and clarity, we want to provide detailed information about the scope of our services and what is not included.</p>

            <h2>Our Core Services</h2>
            <p>At Email Validify, our primary focus is on digital email validation. Our services include:</p>
            <ul>
                <li><strong>Real-Time Email Validation:</strong> Instant verification of email addresses as they are entered.</li>
                <li><strong>Batch Email Processing:</strong> Efficient validation of large lists in bulk.</li>
                <li><strong>Domain and Syntax Checks:</strong> Comprehensive validation of email domains and formatting.</li>
            </ul>

            <h2>What We Do Not Provide</h2>
            <p>We specialize in providing high-quality digital email validation services. As such, we do not offer or handle any physical goods or delivery services. Specifically, our service does not include:</p>
            <ul>
                <li><strong>No Shipping:</strong> We do not handle or provide any physical shipping of products, documents, or materials.</li>
                <li><strong>No Delivery:</strong> Our services do not include the delivery of physical items to any addresses.</li>
                <li><strong>No Courier Services:</strong> We do not offer any courier, logistics, or shipping services.</li>
            </ul>

            <h2>Need Further Assistance?</h2>
            <p>Our focus is solely on delivering accurate and efficient email validation solutions to help you manage and optimize your email communications. If you have any questions or need more information about our services, please contact us:</p>
            <div class="contact-info">
                <p>Email: <a href="mailto:support@emailvalidify.com">support@emailvalidify.com</a></p>
            </div>

            <br />
            <p>Thank you for understanding our service scope! We look forward to helping you achieve your email communication goals!</p>
        </article>
    )
}

export default ServiceScope


