import React from "react";
import PageHeader from '../components/header/PageHeader';
import TermsAndConditions from "../components/docs/TermsAndConditions";

function TermsAndConditionsPage() {
    return (
        <div className="page-content document">
            <PageHeader title={`Terms of Service`} />

            <div className="container">
                <div className="row">
                    <TermsAndConditions />
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditionsPage;