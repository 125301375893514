import React from "react";

const PrivacyPolicy = () => {
    return (
        <article className="document">
            <div className="meta-info"><strong>Last updated:</strong> Aug 17, 2024</div>
            <h2>Introduction</h2>
            <p>Welcome to Email Validify ("we," "our," "us"). This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our email validation services (the "Service"). By accessing or using our Service, you agree to the terms of this Privacy Policy.</p>

            <h2>Information We Collect</h2>

            <h3>Personal Information</h3>
            <p>We collect personal information that you provide to us directly when using our Service. This may include:</p>
            <ul>
                <li><strong>Email Addresses:</strong> Email addresses that you submit for validation.</li>
                <li><strong>Account Information:</strong> Information such as your name, email address, phone number, and payment information when you create an account or make a purchase.</li>
                <li><strong>Contact Information:</strong> Email addresses and other contact information you provide for communication purposes.</li>
            </ul>

            <h3>Usage Data</h3>
            <p>We automatically collect information about your interaction with our Service, including:</p>
            <ul>
                <li><strong>Log Data:</strong> IP address, browser type, operating system, referring URLs, and date/time stamps.</li>
                <li><strong>Usage Information:</strong> Details about your usage of the Service, such as the pages you view, the features you use, and the actions you take.</li>
            </ul>

            <h3>Cookies and Tracking Technologies</h3>
            <p>We use cookies and similar tracking technologies to collect and store information about your interactions with our Service. You can control the use of cookies at the individual browser level.</p>

            <h2>How We Use Your Information</h2>
            <p>We use the information we collect for the following purposes:</p>
            <ul>
                <li><strong>Service Provision:</strong> To validate email addresses and provide other related services.</li>
                <li><strong>Account Management:</strong> To create and manage your account, process payments, and provide customer support.</li>
                <li><strong>Communication:</strong> To communicate with you about your account, transactions, and updates to our Service.</li>
                <li><strong>Improvement and Development:</strong> To understand and analyze how you use our Service, improve functionality, and develop new features.</li>
                <li><strong>Security and Fraud Prevention:</strong> To protect the security of our Service and prevent fraudulent activities.</li>
                <li><strong>Legal Compliance:</strong> To comply with legal obligations and respond to legal requests.</li>
            </ul>

            <h2>Sharing Your Information</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to third parties except in the following circumstances:</p>
            <ul>
                <li><strong>Service Providers:</strong> We may share information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, hosting services, and customer support.</li>
                <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.</li>
                <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
            </ul>

            <h2>Data Security</h2>
            <p>We implement a variety of security measures to protect your information from unauthorized access, use, or disclosure. These measures include:</p>
            <ul>
                <li><strong>Encryption:</strong> Data encryption both in transit and at rest.</li>
                <li><strong>Access Controls:</strong> Restricted access to personal information to authorized personnel only.</li>
                <li><strong>Regular Audits:</strong> Regular security audits and assessments to identify and mitigate potential vulnerabilities.</li>
            </ul>

            <h2>Data Retention</h2>
            <p>We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce our agreements.</p>

            <h2>Data Deletion</h2>
            <p>You have the right to request the deletion of your personal information from our systems. If you would like us to delete your data, please submit a request through our <a href="/account-deletion">Data Deletion Request Form</a>. Upon receiving your request, we will promptly delete your personal information from our records, unless retention is required by law or necessary for legitimate business purposes.</p>

            <h2>Your Rights and Choices</h2>
            <p>Depending on your location, you may have the following rights regarding your personal information:</p>
            <ul>
                <li><strong>Access:</strong> The right to request access to the personal information we hold about you.</li>
                <li><strong>Correction:</strong> The right to request that we correct any inaccurate or incomplete information.</li>
                <li><strong>Deletion:</strong> The right to request that we delete your personal information, subject to certain exceptions.</li>
                <li><strong>Objection:</strong> The right to object to our processing of your personal information.</li>
                <li><strong>Restriction:</strong> The right to request that we restrict the processing of your personal information.</li>
                <li><strong>Portability:</strong> The right to request a copy of your personal information in a structured, machine-readable format.</li>
            </ul>
            <p>To exercise these rights, please contact us using the information provided below.</p>

            <h2>Children's Privacy</h2>
            <p>Our Service is not intended for use by individuals under the age of 16. We do not knowingly collect personal information from children under 16. If we become aware that we have collected personal information from a child under 16, we will take steps to delete such information.</p>

            <h2>Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the new Privacy Policy on our website and updating the "Last Updated" date at the top of this policy.</p>

            <h2>Contact Us</h2>
            <p>If you have any privacy-related questions or comments related to this Privacy Statement, please send an email to <a href="mailto:privacy@emailvalidify.com">privacy@emailvalidify.com</a></p>
            <p>You can also contact us by writing to the following address:</p>
            <p>Email Validify<br />508, Royal Gravitaz, Lalarpura Road<br />Jaipur, Rajasthan 302012, India</p>
        </article>
    )
}


export default PrivacyPolicy;