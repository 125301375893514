import React from "react";

import WhyChooseUsImage from '../../../assets/img/why-choose-us.png'
import ThumpsUpLineIcon from 'remixicon-react/ThumbUpLineIcon'


const WhyChooseUsSection = () => {
    return (
        <section id="why-choose-us">
            <div className="container">
                <h2 className="section-header">Why Choose Us</h2>

                <div className="row">
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-2 title-with-icon" data-aos="fade-up">
                            <ThumpsUpLineIcon size={20} className="icon" />
                            <h3>Accuracy</h3>
                        </div>
                        <p data-aos="fade-up">Our advanced algorithms ensure accurate and reliable email verification.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-2 title-with-icon" data-aos="fade-up" data-aos-delay="100">
                            <ThumpsUpLineIcon size={20} className="icon" />
                            <h3>Ease of Use</h3>
                        </div>
                        <p data-aos="fade-up" data-aos-delay="100">User-friendly interface makes email verification a breeze.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-2 title-with-icon" data-aos="fade-up" data-aos-delay="200">
                            <ThumpsUpLineIcon size={20} className="icon" />
                            <h3>Customer Support</h3>
                        </div>
                        <p data-aos="fade-up" data-aos-delay="200">Dedicated support team ready to assist you every step of the way.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-2 title-with-icon" data-aos="fade-up" data-aos-delay="300">
                            <ThumpsUpLineIcon size={20} className="icon" />
                            <h3>Innovation</h3>
                        </div>
                        <p data-aos="fade-up" data-aos-delay="300">Continuously evolving to meet the changing needs of the industry.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-2 title-with-icon" data-aos="fade-up" data-aos-delay="400">
                            <ThumpsUpLineIcon size={20} className="icon" />
                            <h3>Cost-Effective</h3>
                        </div>
                        <p data-aos="fade-up" data-aos-delay="400">Enjoy significant cost savings with our services, offering rates that are nearly 50% lower than other players in the market. We believe in providing top-notch email verification without breaking the bank.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-2 title-with-icon" data-aos="fade-up" data-aos-delay="500">
                            <ThumpsUpLineIcon size={20} className="icon" />
                            <h3>No Pay for Duplicates or Unverifiable Emails</h3>
                        </div>
                        <p data-aos="fade-up" data-aos-delay="500">Unlike some competitors, we won't charge you for duplicates or unverifiable email addresses. Pay only for the verified, accurate data you need, and avoid unnecessary expenses.</p>
                    </div>

                </div>
            </div>
        </section>
    )
}


export default WhyChooseUsSection;