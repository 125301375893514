import React from "react";

const TermsAndConditions = () => {
    return (
        <article className="document">
            <div className="meta-info"><strong>Last updated:</strong> Aug 11, 2024</div>
            <h2>Introduction</h2>
            <p>Welcome to Email Validify ("we," "our," "us"). These Terms and Conditions ("Terms") govern your use of our email validation services (the "Service"). By accessing or using our Service, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, you must not use our Service.</p>

            <h2>1. Service Overview</h2>
            <p>Our Service provides email validation, verification, and related functionalities to help ensure the accuracy and deliverability of email addresses. We reserve the right to modify or discontinue the Service, in whole or in part, at any time without prior notice.</p>

            <h2>2. User Responsibilities</h2>

            <h3>2.1. Eligibility</h3>
            <p>You must be at least 18 years old or have the consent of a parent or guardian to use our Service.</p>

            <h3>2.2. Account Registration</h3>
            <p>To use certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during registration and to update such information to keep it accurate, current, and complete.</p>

            <h3>2.3. Account Security</h3>
            <p>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>

            <h3>2.4. Prohibited Use</h3>
            <p>You agree not to use the Service for any unlawful or prohibited purpose, including but not limited to:</p>
            <ul>
                <li><strong>Spamming:</strong> Sending unsolicited bulk emails or engaging in any form of spamming.</li>
                <li><strong>Illegal Activities:</strong> Engaging in fraudulent or illegal activities.</li>
                <li><strong>Data Misuse:</strong> Using the Service to collect or harvest personal data without consent.</li>
            </ul>

            <h2>3. Service Fees and Payment</h2>

            <h3>3.1. Pricing</h3>
            <p>The use of our Service may be subject to fees, which will be detailed on our website or communicated to you at the time of purchase.</p>

            <h3>3.2. Payment Terms</h3>
            <p>Email Validify uses PayPal as the payment processor for our Services. All payments must be made in advance, and you agree to pay all applicable fees as outlined in our pricing information. We may change our fees from time to time, and any changes will be communicated to you.</p>

            <h3>3.3. Payment Security</h3>
            <p>We take the security of your payment information seriously. All payment transactions are encrypted and processed securely through PayPal's payment gateway. However, Email Validify is not responsible for any unauthorized access to or misuse of your payment information.</p>

            <h3>3.4. Disputes and Chargebacks</h3>
            <p>If you believe that a payment has been made in error or without authorization, please contact our support team immediately for assistance. Chargebacks and disputes initiated through your payment method provider without prior communication with us may result in additional fees and account suspension.</p>

            <h3>3.5. Refunds</h3>
            <p>Refunds are subject to our refund policy, which can be found on our website or provided upon request.</p>

            <h2>4. Intellectual Property</h2>

            <h3>4.1. Ownership</h3>
            <p>All intellectual property rights in and to the Service, including but not limited to trademarks, copyrights, and patents, are owned by us or our licensors.</p>

            <h3>4.2. License</h3>
            <p>We grant you a limited, non-exclusive, non-transferable license to use the Service for its intended purposes, subject to these Terms.</p>

            <h2>5. Data Privacy and Security</h2>

            <h3>5.1. Data Collection</h3>
            <p>We collect and process personal data in accordance with our Privacy Policy, which describes how we handle your information.</p>

            <h3>5.2. Data Security</h3>
            <p>We implement reasonable security measures to protect your data. However, you acknowledge that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

            <h2>6. Limitation of Liability</h2>

            <h3>6.1. Disclaimer of Warranties</h3>
            <p>The Service is provided "as is" and "as available" without warranties of any kind, either express or implied. We disclaim all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

            <h3>6.2. Limitation of Liability</h3>
            <p>To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Service, even if we have been advised of the possibility of such damages. Our total liability to you for any claim arising out of or relating to these Terms or the Service shall not exceed the amount you paid for the Service in the 12 months preceding the claim.</p>

            <h2>7. Indemnification</h2>
            <p>You agree to indemnify, defend, and hold harmless Email Validify and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, costs, or expenses arising out of or related to your use of the Service, your violation of these Terms, or your violation of any rights of another party.</p>

            <h2>8. Termination</h2>

            <h3>8.1. Termination by Us</h3>
            <p>We may suspend or terminate your access to the Service at any time, with or without cause, if we believe you have violated these Terms or if we are required to do so by law.</p>

            <h3>8.2. Termination by You</h3>
            <p>You may terminate your use of the Service by contacting us and discontinuing use of the Service. Termination will not relieve you of any obligations to pay any outstanding fees.</p>

            <h2>9. Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of Delhi, India, without regard to its conflict of law principles. Any disputes arising out of or relating to these Terms or the Service shall be resolved in the courts located in Delhi, India.</p>

            <h2>10. Changes to These Terms</h2>
            <p>We may update these Terms from time to time. We will notify you of any material changes by posting the new Terms on our website. Your continued use of the Service after such changes constitutes your acceptance of the new Terms.</p>
        </article>
    )
}


export default TermsAndConditions;