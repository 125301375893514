import React from "react";
import PageHeader from '../components/header/PageHeader';
import PrivacyPolicy from "../components/docs/PrivacyPolicy";

function PrivacyPolicyPage() {
    return (
        <div className="page-content document">
            <PageHeader title="Privacy Policy" />

            <div className="container">
                <div className="row">
                    <PrivacyPolicy />
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicyPage;