import React from "react";

function PageHeader({title, image}) {
    const backgroundStyle = image ? { background: `url(${image}) no-repeat center top fixed` } : null

    return (
        <div className={`page-header ${image ? 'has-image' : ''}`} style={backgroundStyle}>
            {image && <div className="overlay" />}
            <h1>{title}</h1>
        </div>
    );
}

export default PageHeader;