import React from "react";

import HeroSection from "./components/HeroSection";
import HowItWorksSection from "./components/HowItWorks";
import KeyFeaturesSection from "./components/KeyFeaturesSection";
import BenefitsSection from "./components/BenefitsSection";
import WhyChooseUsSection from "./components/WhyChooseUsSection";
import FAQSection from "./components/FAQSection";
import PreLaunchOfferModal from "../components/modal/PreLaunchOfferModal";

const MainPage = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    console.log(props)

    return (
        <>
            <HeroSection />
            <HowItWorksSection />
            <KeyFeaturesSection />
            <BenefitsSection />
            <WhyChooseUsSection />
            <FAQSection />

            <PreLaunchOfferModal 
                show={modalShow}
                onHide={() => setModalShow(false)}/>
        </>
    );
}

export default MainPage;