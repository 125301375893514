import React from "react";

import UploadCloudLineIcon from 'remixicon-react/UploadCloudLineIcon'
import ListVerifyLineIcon from 'remixicon-react/MailCheckLineIcon'
import ReportLineIcon from 'remixicon-react/FileChartLineIcon'


const HowItWorksSection = () => {
    return (
        <section id="how-it-works" className="services">

            <div className="container" data-aos="fade-up">

                <h2 className="section-header">How It Works</h2>
                <p className="overview">Using Email Validify is as easy as 1-2-3! Simply sign up for an account, upload your email list, and let our advanced algorithms work their magic. In minutes, you'll receive a detailed report highlighting the status of each email address in your list.</p>

                <div className="row gy-4">
                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                        <div className="service-box blue">
                            <div className="icon">
                                <UploadCloudLineIcon size={32} />
                            </div>
                            <h3>1. Upload</h3>
                            <p>Upload your emails list CSV file to our secure server using our user friendly drag & drop interface</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                        <div className="service-box orange">
                            <div className="icon">
                                <ListVerifyLineIcon size={32} />
                            </div>
                            <h3>2. Verify</h3>
                            <p>Our email validator validate & verify your emails list at real-time</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                        <div className="service-box green">
                            <div className="icon">
                                <ReportLineIcon size={32} />
                            </div>
                            <h3>3. Download</h3>
                            <p>Download your emails list validation results along with all of the information you need</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWorksSection;