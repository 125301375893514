import React from "react";
import PageHeader from '../components/header/PageHeader';
import ServicesBannerImage from '../../assets/img/services/services-banner.jpg'
import OurMissionArticle from "../components/articles/OurMissionArticle";
import HowItWorksArticle from "../components/articles/HowItWorksArticle";

function ServicesPage() {
    return (
        <div className="page-content">
            <PageHeader title="Our Services" image={ServicesBannerImage} />

            <div className="container">
                <div className="row">
                    <div className="col-md-7 about-content">
                        <h2 className='section-header'>Email Verification Solutions</h2>
                        <p>Email Validify, your trusted partner, provides essential tools for businesses and individuals to ensure the accuracy and deliverability of email addresses. Our email verification service verifies whether an email address is valid and capable of receiving emails. This helps reduce bounce rates, improve email deliverability, and maintain a clean email list. Our email validation services include:</p>
                        <ul className="key-list">
                            <li><span className="highlighted">Syntax and Formatting Check:</span> Ensures email addresses conform to standard email formatting rules.</li>
                            <li><span className="highlighted">Domain Validation:</span> Checks if the domain is valid and active.</li>
                            <li><span className="highlighted">MX Record Check:</span> Verifies the existence of mail exchange records for the domain.</li>
                            <li><span className="highlighted">SMTP Authentication:</span> Communicates with the mail server to ensure the mailbox exists.</li>
                            <li><span className="highlighted">Disposable Email Detection:</span> Detects temporary or disposable email addresses.</li>
                            <li><span className="highlighted">Emails Validation Report</span> Report detailing the results of the email validation process.</li>
                        </ul>
                        <h2 className='section-header'>Key Features</h2>
                        <ul className="key-list features">
                            <li>
                                <h3 className="title">Real-Time Verification</h3>
                                <p className="description">Effortlessly validate large email lists with our bulk verification feature.</p>
                            </li>
                            <li>
                                <h3 className="title">Bulk Verification</h3>
                                <p className="description">Effortlessly validate large email lists with our bulk verification feature.</p>
                            </li>
                            <li>
                                <h3 className="title">Syntax and Format Checking</h3>
                                <p className="description">Ensure correct email syntax and formatting to reduce bounce rates.</p>
                            </li>
                            <li>
                                <h3 className="title">Domain Validation</h3>
                                <p className="description">Validate email domains to enhance email deliverability</p>
                            </li>
                            <li>
                                <h3 className="title">Spam Trap Detection</h3>
                                <p className="description">Identify and avoid potential spam traps that could harm your sender reputation.</p>
                            </li>
                            <li>
                                <h3 className="title">Duplicate Emails Checker</h3>
                                <p className="description">Seamlessly filter the list for duplicates</p>
                            </li>
                        </ul>

                        <h2 className='section-header'>Key Benefits</h2>
                        <ul className="key-list benefits">
                            <li>
                                <h3 className="title">Free Credits</h3>
                                <div className="description">Join Email Validify and receive 100 free credits every month. Experience the power of email verification at no cost!</div>
                            </li>
                            <li>
                                <h3 className="title">Email Validation Pricing</h3>
                                <p className="description">Enjoy significant cost savings with our services, offering rates that are nearly 50% lower than other players in the market. We believe in providing top-notch email verification without breaking the bank.</p>
                            </li>
                            <li>
                                <h3 className="title">Improved Deliverability</h3>
                                <p className="description">Ensure your promotional messages reach the right audience with a verified email list.</p>
                            </li>
                            <li>
                                <h3 className="title">Reduced Bounce Rates</h3>
                                <p className="description">Say goodbye to undeliverable emails and minimize bounce rates.</p>
                            </li>
                            <li>
                                <h3 className="title">Time and Cost Savings</h3>
                                <div className="description">Save time and resources with our efficient email verification process and target the verified audiences</div>
                            </li>
                            <li>
                                <h3 className="title">Enhanced Sender Reputation</h3>
                                <p className="description">Maintain a positive sender reputation for better inbox placement.</p>
                            </li>
                            <li>
                                <h3 className="title">No Pay for Duplicates or Unverifiable Emails</h3>
                                <p className="description">Unlike some competitors, we won't charge you for duplicates or unverifiable email addresses. Pay only for the verified, accurate data you need, and avoid unnecessary expenses.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-5">
                        <OurMissionArticle />
                        <HowItWorksArticle />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesPage;