import React, { Component } from 'react';
import PageHeader from '../components/header/PageHeader';
import PricingBannerImage from '../../assets/img/pricing/pricing-banner.jpg'
import config from '../../config';

export class PricingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPreLaunchOfferEnabled: false,
            selectedCurrency: {
                code: 'INR',
                symbol: '₹'
            },
            creditRates: {
                0: 0.35,
                1000: 0.35,
                2000: 0.25,
                5000: 0.22,
                10000: 0.19,
                25000: 0.16,
                50000: 0.11,
                100000: 0.08,
                500000: 0.06,
                1000000: 0.05
            },
            creditsInput: 1000,
            selectedCount: 1000
        }
    }

    componentDidMount() {
        this.fetchCreditsRate()
    }

    render() {
        return (
            <div className='page-content pricing'>
                <PageHeader title="Pricing" image={PricingBannerImage} />

                <div className='container'>
                    <h2 className='section-header'>Pricing Plans</h2>
                    <p>At <a href='/' className='highlighted'>Email Validify</a>, we offer flexible pricing options to meet the needs of businesses of all sizes. Whether you're just getting started or need to validate millions of email addresses, our pricing plans provide the value and flexibility you need.</p>
                    <h3>Free Plan</h3>
                    <p><span className='highlighted'>100 Free Credits Every Month:</span> Start with 100 free email validation credits each month to experience our service with no commitment. Perfect for small businesses or those wanting to test our service.  Experience the power of email verification at no cost!</p>
                    <h3>Pay-as-You-Go</h3>
                    <p>Our Pay-as-You-Go model allows you to purchase credits as needed, giving you complete control over your spending.</p>
                    <p>The more you buy, the more you save. Purchase only what you need without any long-term commitment.</p>
                    {this.renderPricingCard()}
                    <p className='text-hint'>Enjoy the flexibility of purchasing a minimum of 1000 credits with no constraints on their usage time or expiration. Utilize them at your convenience, whenever it aligns with your needs.</p>
                    <h2 className='section-header'>Additional Information</h2>
                    <h3>Payment Methods</h3>
                    <p>We support payments through PayPal, ensuring secure and convenient transactions.</p>
                    <h3>Refund Policy</h3>
                    <p>For details on our refund policy, please refer to our Refund Policy.</p>
                    <h3>Customer Support</h3>
                    <p>Our dedicated support team is here to help you with any questions or issues. Contact us at <a href="mailto:support@emailvalidify.com">support@emailvalidify.com</a></p>

                    <article className='highlighted'>
                        <h3>Get Started Today!</h3>
                        <p>Sign up now and get 100 free credits each month. Experience the reliability and accuracy of Email Validify's email validation services and start improving your email deliverability today.</p>
                        <p>By offering a variety of pricing options, we aim to provide the best value for our customers, ensuring that you only pay for what you need while benefiting from our high-quality email validation services.</p>
                    </article>

                </div>
            </div>
        );
    }

    renderPricingCard = () => {
        const { isPreLaunchOfferEnabled, creditRates, selectedCount, creditsInput, selectedCurrency } = this.state
        const pricePerCredit = creditRates[selectedCount]
        const totalPrice = (pricePerCredit * creditsInput).toFixed(2).toString()
        const totalPriceNonFractional = totalPrice.split('.').length > 0 ? totalPrice.split('.')[0] : totalPrice
        const totalPriceFractional = totalPrice.split('.').length > 0 ? totalPrice.split('.')[1] : '00'
        const pricePerCreditFormatted = `${pricePerCredit.toFixed(4)} per credit`

        return (
            <div className='card pricing'>
                <div className='card-body'>
                    <div className='pricing-box'>
                        <div className='row'>
                            <div className='col-md-6 d-flex'>
                                <div className='credits-rate'>
                                    {Object.keys(creditRates).map(count => {
                                        return count != 0 && (
                                            <div key={`credits_${count}`}
                                                className={`card-credits ${selectedCount == count ? 'selected' : ''}`}
                                                onClick={() => this.setState({ selectedCount: count, creditsInput: count })}>
                                                <div className='card-text'>
                                                    <span className='text-label'>{count}</span>
                                                    <span className='text-hint'>credits for</span>
                                                    <span className='text-label'>{`${selectedCurrency.symbol}${(creditRates[count] * count).toFixed(2)}`}</span>
                                                </div>
                                                <i className='mdi mdi mdi-check-circle' />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='col-md-6 d-flex'>
                                <div className='credits-preview'>
                                    <div className='top-view'>
                                        <div className='purchase-amount'>
                                            <span>{selectedCurrency.symbol}</span>
                                            {totalPriceNonFractional}
                                            <span>.{totalPriceFractional}</span>
                                        </div>
                                        <div>{pricePerCreditFormatted}</div>
                                    </div>
                                    <div className='card-credits-rows'>
                                            {isPreLaunchOfferEnabled ? (
                                                <div className='card-credits'>
                                                    <div className='text-label highlighted'>Pre Launch Offer</div>
                                                    <div className='spacer'></div>
                                                    <div className='text-label'>{`-${selectedCurrency.symbol}${(totalPrice/2).toFixed(2)}`}</div>
                                                </div>
                                            ) : null}
                                            <div className='card-credits'>
                                                <div className='text-label'>{`${creditsInput} credits`}</div>
                                                <div className='text-label'>{`${selectedCurrency.symbol}${(isPreLaunchOfferEnabled ? totalPrice/2 : Number(totalPrice)).toFixed(2)}`}</div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChangeCreditsInput = e => {
        const { creditRates } = this.state
        let inputValue = parseInt(e.target.value) || 0;
        const creditPacks = Object.keys(creditRates).map(pack => parseInt(pack));

        let selectedCount = 1;
        for (let i = 0; i < creditPacks.length - 1; i++) {
            if (inputValue >= creditPacks[i] && inputValue < creditPacks[i + 1]) {
                selectedCount = creditPacks[i];
                break;
            }
        }

        const smallestPack = creditPacks[0]
        const largestPack = creditPacks[creditPacks.length - 1]
        if (inputValue > largestPack) {
            selectedCount = largestPack;
            inputValue = largestPack
        } else if (inputValue <= smallestPack) {
            selectedCount = smallestPack
            inputValue = smallestPack
        }

        this.setState({ creditsInput: inputValue, selectedCount });
    }

    fetchCreditsRate = async () => {
        const response = await fetch(`${config.API_BASE}/v1/cs/pricing`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const responseData = await response.json()

        if (responseData) {
            this.setState({selectedCurrency: responseData.currency, creditRates: responseData.creditRates});
        }
    }
}

export default PricingPage;