import React from "react";

import Value1Img from '../../../assets/img/values-1.png'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'


const BenefitsSection = () => {
    return (
        <section id="benefits">
            <div className="container">
                <div className="row feature-icons" data-aos="fade-up">
                    <h2 className="section-header">Benefits</h2>

                    <div className="row">

                        <div className="col-xl-12 d-flex content">
                            <div className="row align-self-center gy-4">

                                <div className="col-md-6 icon-box" data-aos="fade-up">
                                    <CheckLineIcon size={24} className="icon" />
                                    <div>
                                        <h3>Improved Deliverability</h3>
                                        <p>Ensure your promotional messages reach the right audience with a verified email list.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                                    <CheckLineIcon size={24} className="icon" />
                                    <div>
                                        <h3>Reduced Bounce Rates</h3>
                                        <p>Say goodbye to undeliverable emails and minimize bounce rates.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                                    <CheckLineIcon size={24} className="icon" />
                                    <div>
                                        <h3>Time and Cost Savings</h3>
                                        <p>Save time and resources with our efficient email verification process and target the verified audiences</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                    <CheckLineIcon size={24} className="icon" />
                                    <div>
                                        <h3>Enhanced Sender Reputation</h3>
                                        <p>Maintain a positive sender reputation for better inbox placement.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default BenefitsSection;