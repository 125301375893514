import React from "react";
import Accordion from 'react-bootstrap/Accordion';

const FAQSection = () => {
    return (
        <section id="faq" className="faq">

            <div className="container" data-aos="fade-up">

                <h2 className="section-header">Frequently Asked Questions</h2>

                <div className="row">
                    <div className="col-lg-6">
                        <Accordion flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How does email verification work?</Accordion.Header>
                                <Accordion.Body>
                                    Upload your emails list CSV file, our email validator validate & verify your emails list at real-time. In minutes, you'll receive a detailed report highlighting the status of each email address in your list.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What are the benefits of using your service?</Accordion.Header>
                                <Accordion.Body>
                                    We offer rates that are nearly 50% lower than other players in the market. Unlike some competitors, we won't charge you for duplicates or unverifiable email addresses. Pay only for the verified, accurate data you need, and avoid unnecessary expenses.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <div className="col-lg-6">

                        <Accordion flush>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Is my data secure during the verification process?</Accordion.Header>
                                <Accordion.Body>
                                Absolutely, we prioritize the security of your data throughout the verification process. We employ advanced technologies, including multiple layers of encryption and web sockets, to ensure that your information remains confidential and protected from unauthorized access. Our commitment to data security is unwavering, and we continuously update and improve our systems to stay ahead of emerging threats. You can trust that your data is in safe hands during the verification process.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Do you offer bulk verification services?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, we validate large email lists with our bulk verification feature. Upload your emails list CSV file to our secure server, our email validator validate & verify your emails list at real-time, download your emails list validation results along with all of the information you need
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                </div>

            </div>

        </section>
    )
}

export default FAQSection;